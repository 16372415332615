import Team from "./Team"

import Usp from "./Usp"
import References from "./References";
import Header from "./Common/Header";
import HomepageAbout from "./homepage_about";
// import HomepageHamilton from "./homepage_hamilton";
import Footer from "./Common/Footer";
import Hero from "./Common/Hero";
// import { useState } from "react";

function App() {
  // console.log(home[0])
  // const [language, setLanguage] = useState("dutch");
  // console.log(language)
  return (
    <div className="App">
      <Header />
      <Hero />
      <main>
        <HomepageAbout />
        <Usp />
        {/* <HomepageHamilton/> */}
          {/* <div>
            <button className="switch" onClick={() => setLanguage("english")}>Switch Language</button>
            {language === "english" 
              ? 
                <h1>ENGLISH</h1> 
              : 
                <h1>DUTCH</h1>
            }
          </div> */}
          <References />
          <Team />
        </main>
        <Footer />
      </div>
  );
}

export default App;
