// import { Link } from "react-router-dom";
import LazyHero from 'react-lazy-hero';
import HeroImg from '../../../../img/home/hero_img.png'


function Hero() {
    return (
        <div id="hero">
            <LazyHero imageSrc={HeroImg} isCentered={true} color="#f6fbf8">
                <div className="padd">
                    <h2>Extensive experience in technical services and laboratory equipment.</h2>
                    <p>We have extensive experience in Fermentation/Cell Culture and various laboratory equipment.</p>
                    {/* <Link to="/" className="orange_btn">Lees meer</Link> */}
                </ div>
            </LazyHero>
        </div>  
    );
  }
  
  export default Hero;
  