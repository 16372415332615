// import Office from '../../img/home/office.jpg'
// import { Link } from 'react-router-dom';
import home from '../../home.json'

function Homepage() {
    // console.log('deze')
    // console.log(home)
    const sortedhome = home.sort((a, b) => a.index - b.index)
    return (
        <>
        {sortedhome.map((item, index) => {
            return (
                <div className="home_flex">
                    <div>
                        <img src={item.image} alt="office biotech service" className="home_image"/>
                    </div>
                    <div>
                        <h1>{item.header}</h1>
                        <p>{item.content}</p>
                        {/* <Link to="/" className="orange_btn">Lees meer</Link> */}
                    </div>
                </div>
            )})}
            {/* <div className="home_flex">
                <div>
                    <img src={home[1].image} alt="office biotech service" className="home_image"/>
                </div>
                <div>
                    <h1>{home[1].header}</h1>
                    <p>{home[1].content}</p>
                    <Link to="/" className="orange_btn">Lees meer</Link>
                </div>
            </div> */}
        </>
    );
  }
  
  export default Homepage;
  