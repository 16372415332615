import { Link } from "react-router-dom";
import team from '../../../team.json'
import GMP from '../../../img/certificates/GMP.png'
import ISO from '../../../img/certificates/Kiwa_ISO_9001.jpeg'
import VCA from '../../../img/certificates/VCA_logo_1000x569px_RGB_2.0.png'
import NEN from '../../../img/certificates/nen_3140_logo.png'
import Certificate from '../../../img/certificates/K_0218988_1_Certificaat.pdf'

function Footer() {
    return (
        <div className="total-footer">
            <div className="top-footer">
                <div className="contact">
                    <h1>Certificaten</h1>
                    {/*<div className="order-form">
                        <form name="contact" method="post" action="/success" data-netlify-recaptcha="true" >
                            <input type="hidden" name="form-name" value="contact" />
                            <div className="form-padding">
                                <p className="d-flex flex-column">
                                    <input type="text" name="name" placeholder="Uw naam" required/>
                                </p>
                                <p className="d-flex flex-column">
                                    <input type="text" name="email" placeholder="Uw e-mail" required/>
                                </p>
                                <p className="d-flex flex-column">
                                    <input type="text" name="number" placeholder="Uw telefoonnummer" required/>
                                </p>
                                <p className="d-flex flex-column">
                                    <input type="text" name="subject" placeholder="Onderwerp" required/>
                                </p>
                                <p className="d-flex flex-column">
                                    <input type="text" name="message" placeholder="Uw bericht" required/>
                                </p>
                                <p>
                                    <button type="submit" formAction="/success" id="submit-button">Verzenden</button>
                                </p>
                            </div>
                        </form>
                    </div> */}
                    <div className="certificates">
                        <div>
                            <img src={GMP} alt="GMP certificate" className="certificate"/>
                        </div>
                        <div>
                            {/* // When i click on this image, i want to open the chrome pdf previewer with the file Certificate  */}
                            <a href={Certificate} target="_blank" rel="noreferrer">
                                <img src={ISO} alt="ISO certificate" className="certificate"/>
                            </a>
                        </div>
                        <div>
                            <img src={VCA} alt="VCA certificate" className="certificate"/>
                        </div>
                        <div>
                            <img src={NEN} alt="VCU certificate" className="certificate"/>
                        </div>
                    </div>
                </div>
                <div className="info">
                    <h1>Biotech Service</h1>
                    <p className="contact-name">Haarbrug 4</p>
                    <p className="contact-name">3751 LM</p>
                    <p className="contact-name">Bunschoten Spakenburg</p>
                    <p className="contact-name">info@biotechservice.nl</p>
                    {team.map((content, i) => {
                    return(
                        <div className="footer-team">
                            <p>{content.phone}</p>
                            <p className="team-name">{content.name}</p>
                            <p>{content.function}</p>
                        </div>
                    )
                    })}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2443.7176612106596!2d5.3755245157967035!3d52.230347179760344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47c64752fb3cb4d7%3A0xdc722fab0be5ba82!2sHaarbrug%204%2C%203751%20LM%20Bunschoten-Spakenburg!5e0!3m2!1sen!2snl!4v1644247745059!5m2!1sen!2snl" width="600" height="450" allowfullscreen="" loading="lazy" title="map"></iframe>
                </div>
            </div>
            <footer className="App-footer">
                <div>
                <p>
                    Biotech Service
                </p>
                </div>
                <div>
                    <Link to="/"></Link>
                    <Link to="/"></Link>
                    <Link to="/"></Link>
                    <Link to="/"></Link>
                    <Link to="/"></Link>
                </div>
            </footer>
        </div>
    );
  }
  
  export default Footer;
  