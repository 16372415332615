import usp from '../../../usp_english.json'
import atom from '../../../img/icons/atom.png'

function Usp() {
    console.log(usp)
    return (
      <>
        <h1>Why Biotech Service?</h1>
        <div className="usp-bar">
          {usp.map((content, i) => {
            return(
              <div className='usp-card'>
                <div className='atom-flex'>
                  <img src={atom} alt="atom" />
                </div>
                <h1>{content.title}</h1>
                <p>{content.content}</p>
              </div>
            )
          })}
        </div>
    </>
    );
  }
  
  export default Usp;
  