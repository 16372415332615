import references from '../../references.json'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function References() {
    console.log(references)
    return (
        <>
        <h1>Referenties</h1>
        <div className='carou'>
            <Carousel autoPlay="true" interval={2000} infiniteLoop="true" dynamicHeight="true">
            {references.map((content, i) => {
              return(
                <div className='carou-item'>
                  {/* <p className="legend">{content.title}</p> */}
                  <img src={content.image} alt={content.title} href={content.url} />
                </div>
              )
            })}
            </Carousel>
        </div>
        </>
    );
  }
  
  export default References;
  