import team from '../../../team.json'

function Team() {
    return (
        <>
        <h1>Our Team</h1>
        <div className='team-flex'>
          <div className='team-image'></div>
        </div>
        <div className="team-wrapper">
          {team.map((content, i) => {
            return(
              <div className="team-card">
                <img src={content.image} alt={content.name} />
                <p className="team-name">{content.name}</p>
                <div className='team-contact'>
                  <p>{content.phone}</p>
                  {/* <p>{content.linkedin}</p> */}
                </div>
              </div>
            )
          })}
          </div>
        </>
    );
  }
  
  export default Team;
  