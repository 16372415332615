// import { Link } from "react-router-dom";
import LazyHero from 'react-lazy-hero';
import HeroImg from '../../../img/home/hero_img.png'


function Hero() {
    return (
        <div id="hero">
            <LazyHero imageSrc={HeroImg} isCentered={true} color="#f6fbf8">
                <div className="padd">
                    <h2>Ruime ervaring in de technische dienstverlening en laboratoriumapparatuur</h2>
                    <p>We hebben een ruime ervaring in Fermentatie / Cell Culture en diverse Laboratorium apparatuur.</p>
                    {/* <Link to="/" className="orange_btn">Lees meer</Link> */}
                </ div>
            </LazyHero>
        </div>  
    );
  }
  
  export default Hero;
  