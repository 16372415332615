import Team from "./components/Team"
import Usp from "./components/Usp"
import References from "./components/References";
import Header from "./components/Common/Header";
import HomepageAbout from "./components/homepage_about";
// import HomepageHamilton from "./components/homepage_hamilton";
import Footer from "./components/Common/Footer";
import Hero from "./components/Common/Hero";
// import { useState } from "react";

function App() {
  // console.log(home[0])
  // const [language, setLanguage] = useState("dutch");
  // console.log(language)
  return (
    <div className="App">
      <Header />
      <Hero />
      <main>
        <HomepageAbout />
        <Usp />
        {/* <HomepageHamilton/> */}
          <References />
          <Team />
        </main>
        <Footer />
      </div>
  );
}

export default App;
