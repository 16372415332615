import { Link } from "react-router-dom";
import Logo from '../../../img/logo/logo_biotech.svg'

function Header() {
    return (
        <>
            <header className="App-header">
                <div className="mx-width">
                    <div>
                        <img src={Logo} alt="logo biotech service" className="logo"/>
                    </div>
                    <div>
                        <Link to="/">Home</Link>
                        <Link to="/">Over ons</Link>
                        <Link to="/">Hamilton</Link>
                        <Link to="/">Referencies</Link>
                        <Link to="/">Contact</Link>
                        <Link to="/english" onClick={() => {window.location.href="/english"}}>🇬🇧</Link>
                    </div>
                </div>
            </header>
        </>
    );
  }
  
  export default Header;
  